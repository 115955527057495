import {initElm} from './index'
import {intercom} from './intercom'

window.addEventListener('load', () => {
    fetch('/config.json')
        .then(r => r.json())
        .then(config => {
            const app = initElm(config.backendHost, config.docGeneratorUrl, config.iesmUrl)
            if (config.intercomAppId) {
                intercom(config.intercomAppId, app)
            }
        })
        .catch(e => console.error(e))
})